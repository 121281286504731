/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CartItem {
    --header-color: var(--color-black);

    border-block-end: 1px solid var(--primary-divider-color);

    &-Freebie {
        color: #00741B;
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
        background: #E9FFEF;
        padding: 8px;
        border-radius: 8px;
    }

    &-OutOfStock {
        font-weight: bold;
        margin-block-end: 5px;
        font-size: 14px;
        color: #F06F6F;
    }

    &-Seller .Seller .CartItem-BrandnameText {
        font-size: 12px;
        font-weight: 600;
        color: #515150;

        &:hover {
            text-decoration: underline;
            color: var(--primary-base-color);
        }
    }

    &-QuantityWrapperFreeText {
        font-size: 14px;
        color: var(--primary-base-color);
        font-weight: 600;
        padding-inline-end: 8px;
    }

    &-QuantityWrapperFreeQuantity {
        font-size: 14px;
    }


    &-Link {
        color: inherit;
        font-weight: 400;
        align-self: start;

        @include mobile {
            align-self: unset;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &_isCartOverlay:last-of-type {
        border-block-end: none;
    }

    &-Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
    }

    &-Wrapper {
        display: grid;
        grid-gap: 12px;
        padding: 12px;
        background: var(--color-white);
        align-items: center;
        grid-template-columns: minmax(100px, 3fr) 2fr 1fr;

        &_isMobileLayout {
            grid-template-columns: 92px auto;
            grid-gap: 14px;
            padding: 7px 0;
            border-bottom: 1px solid black;
            padding-block-end: 5%;

            @include desktop {
                grid-template-columns: 116px auto;
                padding: 24px 10px;
            }
        }

        &_isSummary {
            grid-template-columns: 96px auto;

            & .CartItem-ProductInfo {
                justify-content: space-between;
            }
        }

        &_isCart {
            grid-template-columns: minmax(100px, 3fr) 1fr;
            border-radius: 8px;
            border: 1px solid #dfdfdf;
            padding: 0;

            .CartItem {
                &-ProductInfo {
                    display: grid;
                    grid-template-columns: 76px 1fr;
                    padding: 1rem 1rem 1rem 16px;
                }

                &-Picture {
                    width: 76px;
                    height: 76px;
                }

                &-Qty {
                    width: 96px;
                    height: 32px;
                }

                &-Link {
                    height: 100%;
                }

                &-ProductDetails {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-inline-start: 16px;
                }

                &-ProductImageQty {
                    display: grid;
                    grid-gap: 16px;
                    padding-block-start: 4px;
                }

                &-Title {
                    // height: 110px;

                    .Seller {
                        padding-block-end: 10px;
                    }
                }

                &-Delete {
                    margin-inline-start: 0;
                    margin-inline-end: 0;
                    cursor: pointer;
                    height: 50%;

                    p {
                        margin-block-end: 0;
                        color: #9d9d9d;
                        padding: 10px 10px 0 10px;
                    }
                }

                &-ProductActions {
                    display: flex;
                    // flex-direction: column;
                    height: 107px;
                    justify-content: space-between;
                    text-align: end;
                }

                &-ProductPriceDetails {
                    width: 100%;
                    display: flex;
                    height: 110px;
                    // flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    gap: 4rem;

                    @include desktop {
                        width: 8rem;
                    }

                    .ProductPrice.CartItem-Price {
                        margin-block-start: 0;
                    }

                    .DiscountedPrice {
                        color: #E84620;
                    }
                }

                &-ProductStockStatus {
                    width: 100%;
                    
                   
                    p {
                        color: var(--primary-base-color);
                        font-size: 16px;
                        font-weight: 700;
                        margin-block-end: 0;
                    }
                }

                &-Discount {
                    width: 100%;

                    &-Percentage {
                        margin-block-end: 10px;
                        letter-spacing: 0px;
                        color: var(--primary-base-color);
                        font-size: 14px;
                        font-weight: bold;
                    }

                    &-Label {
                        letter-spacing: 0px;
                        color: #707070;
                        font-size: 12px;
                    }
                }

                &-Options {
                    // padding-block-end: 16px;
                    font-weight: 400;
                    padding-block-end: 0;
                    // @include desktop {
                    //     height: 0;
                    //     padding-block-end: 0;
                    // }

                    span {
                        font-size: 12px;
                        color: #515150;
                    }

                    .packe_value {
                        color: #000000;
                        font-weight: 600;
                    }
                }

                &-Heading {
                    font-weight: 600;
                    font-size: 16px;

                    @include mobile {
                        font-size: 14px;
                    }
                }

                &-ProductMoveWishlist {
                    // margin-inline-start: 16px;
                    height: 50%;

                    .ProductWishlistButton {
                        // height: unset;
                        // width: unset;
                        position: relative;
                    }

                    .ProductWishlistButton-Button.Button {
                        color: transparent;
                        margin-block-end: 0;
                        text-transform: unset;
                        font-weight: normal;
                        font-size: 14px;
                        background: transparent;
                        width: 40px;
                        z-index: 1;
                    }

                    &::after {
                        padding: 0;
                        position: absolute;
                        inset-inline-start: 45%;
                        inset-block-start: 25%;
                        content: url("src/util/Images/Favourite.svg");
                    }

                    &:has(.ProductWishlistButton-Button_isInWishlist)::after {
                        content: url("src/util/Images/filledHeart.svg");
                    }

                    &.freebee {
                        opacity: 0;
                        pointer-events: none;
                    }
                }

                &-ProductActionButtons {
                    display: flex;
                    color: #707070;
                    align-items: baseline;
                    flex-direction: column;
                    justify-content: space-around;
                    height: 100%;
                    border-left: 1px solid #dfdfdf;

                    &.freebee {
                        border-left: 1px solid #ffffff;

                        .CartItem-MiddleLine {
                            background: #ffffff;
                        }
                    }
                }

                &-StockStatus.instock {
                    padding: 0;
                    position: absolute;
                    inset-block-end: -25px;
                }
            }
        }

        &_isMobileLayout.MobileCart.CartItem-Wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            border-radius: 8px;
            border: 1px solid #dfdfdf;
            padding: 0;
            gap: 5px;

            .CartItem-InfoWrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 7px 10px 6px 10px;
                border-bottom: 1px solid #dfdfdf;
                gap: 10px;

                .CartItem-ProductName {
                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        line-height: 21px;
                        max-height: 48px;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }
                }
            }

            .ProductWishlistButton-Button.Button {
                color: transparent;
                margin-block-end: 0;
                text-transform: unset;
                font-weight: normal;
                font-size: 14px;
                position: absolute;
            }

            .CartItem-ProductMoveWishlist {
                display: flex;
                align-items: center;
                height: 10rem;

                &::after {
                    padding: 0 0 10px 10px;
                    content: url("src/util/Images/Favourite.svg");
                }

                &.freebee {
                    opacity: 0;
                    pointer-events: none;
                }
            }

            .CartItem-ProductMoveWishlist:has(.ProductWishlistButton-Button_isInWishlist)::after {
                content: url("src/util/Images/filledHeart.svg");
            }

            .CartItem-Delete {
                display: block;
                position: absolute;
                inset-inline-end: 0;
            }

            .Remove {
                margin-block-end: 0;
            }

            .CartItem {
                &-ProductPriceDataWrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 0 10px 0 10px;
                }

                &-Price_isMobileLayout.ProductPrice {
                    gap: 1rem;
                    flex-direction: row;

                    @include small-mobile {
                        flex-direction: column;
                        gap: 0;
                    }

                    .DiscountedPrice {
                        color: #E84620;
                    }

                    .RegularPrice del {
                        margin-block-start: 0;
                        font-size: 14px;
                    }
                }

                &-ProductActions_isMobileLayout {
                    align-items: center;
                }

                &-ProductPriceData {
                    justify-content: space-between;
                    width: 100%;
                }

                &-Qty {
                    border: none;
                }
            }
        }
    }

    &_isEditing {
        border-bottom: unset;
        //Removing the % as its not the part of Figma

        .CartItem-Discount {
            display: none;
        }

        .CartItem-Qty {
            border: none;
        }
    }

    &-ProductInfo {
        // display: flex;

        &_isMobileLayout {
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &-Options {
        color: #0f0f0f;
        font-size: 12px;

        .packe_value {
            margin-inline-start: 5px;
            font-weight: 600;
        }

        @include desktop {
            font-size: 14px;
        }

        .Seeds {
            color: var(--primary-base-color);
            margin-block-end: 0%;
            font-size: 12px;
        }
    }

    &-Option {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 16px;
        display: flex;
        gap: 4px;

        &_isBundle {
            flex-direction: column;
            gap: 0;
        }

        span,
        div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &-ItemLinks {
        &Wrapper {
            display: flex;
            flex-direction: column;
            color: var(--secondary-dark-color);
            font-size: 12px;
        }
    }

    &-Title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 65%;
        overflow: hidden;

        &_isMobileLayout {
            width: inherit;
        }

        @include mobile {
            margin-block-end: 12px;
        }

        .Seller {
            color: var(--primary-base-color);
            margin-block-end: 0px;
            font-family: "Lato", sans-serif;
            font-size: 12px;
            line-height: 1.5;
            padding-block-end: 2%;

            @include desktop {
                font-size: 14px;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .Packsize {
            font-size: 14px;
            color: #707070;
            font-family: "Lato", sans-serif;
            padding-block-end: 2%;
            margin-block-end: 0px;
        }

        .No-Pack {
            display: flex;

            .Pack-Items {
                font-size: 14px;
                color: #0f0f0f;
                border: 1px solid #9d9d9d;
                padding: 2% 5%;
                margin-inline-end: 5%;
                border-radius: 2px;
                font-family: "Lato", sans-serif;
                font-weight: bold;

                &:hover {
                    background-color: var(--primary-base-color);
                    color: #ffffff;
                }
            }
        }
    }

    &-Heading,
    &-SubHeading {
        font-size: 14px;
        line-height: 1.2;
        width: 100%;
        font-family: "Lato", sans-serif;
        margin-block-end: 0px;

        @include mobile {
            // max-width: 95%;
            margin-block-end: 2px;
            margin-block-start: 2px;
        }
    }

    &-Heading {
        color: var(--color-black);
        font-family: $font-Montserrat;
        font-weight: bold;
        overflow: hidden;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        // padding-block-end: 2%;

        @include mobile {
            margin-block-end: 8px;
        }

        @include desktop {
            padding-block-end: 12px;
        }
    }

    &-Product-sku {
        color: #707070;
        text-transform: uppercase;

        @include mobile {
            font-size: 12px;
            overflow: hidden;
            line-height: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-block-end: 8px;
        }

        @include desktop {
            margin-block-end: 12px;
        }
    }

    &-SubHeading {
        color: var(--cart-item-subheading-color);
    }

    &-Picture {
        width: 96px;
        height: 96px;
        align-self: flex-start;

        img {
            object-position: top;
            object-fit: fill;
            border-radius: 4px;
        }

        &_isMobileLayout {
            width: 92px;
            height: 92px;

            @include desktop {
                width: 116px;
                height: 116px;
            }
        }
    }

    &-Price {
        text-align: end;
        font-size: 18px;

        &_isMobileLayout {
            text-align: start;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            font-weight: 700;
            font-size: 16px;
            margin-block-end: 0px;
        }

        &_isCartOverlay data {
            font-size: 20px;
            line-height: 20px;

            @include mobile {
                font-size: 12px;
            }
        }
    }

    &-ProductStockStatus {
        width: 100%;
        
        &_isMobileLayout {
            display: flex;
            justify-content: end;
            margin-block-end: 1%;

            p {
                color: var(--primary-base-color);
                font-size: 16px;
                font-weight: 700;
                margin-block-end: 0;
            }  
        }
    }

    &-ProductActions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        // flex-direction: row-reverse;

        &_isMobileLayout {
            justify-content: space-between;
            margin-block-end: 1%;

            @include mobile {
                height: 38px ;
                flex-direction: initial;
                justify-content: space-between;
            }

            &:has(.out-of-stock) {
                @include mobile {
                    gap: 0;
                }
            }
        }

        .Remove {
            color: #9d9d9d;
            font-size: 14px;
            font-family: "Lato", sans-serif;
            margin-block-end: 0px;
            // min-width: 52px;
            // text-decoration: underline;

            @include mobile {
                text-decoration: unset;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .Image-Quantity {
        display: flex;
        grid-row-gap: 10px;
        flex-direction: column;

        @media only screen and (max-width: 810px) {
            grid-row-gap: 17px;
        }
    }

    &-CartItemRows {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
    }

    &-Delete {
        align-items: center;
        display: flex;
        z-index: 5;
        font-size: 14px;
        margin-inline-start: 8px;

        &:hover {
            color: var(--primary-base-color);

            .CloseIcon {
                fill: var(--primary-base-color);
            }
        }

        &_isMobileLayout {
            height: 12px;
            padding-inline-start: 0;
        }
    }

    &-DeleteButtonText {
        color: inherit;
        line-height: 20px;
        margin-inline-start: 4px;
        cursor: pointer;

        &_isMobileLayout {
            display: none;
        }

        @include tablet {
            display: none;
        }

        @include narrow-desktop {
            display: none;
        }
    }

    &-Qty {
        // hack to obtain required specificity
        margin-block-start: 0;
        display: flex;
        cursor: default;
        justify-content: center;
        background: #F9F9F9;
        border-radius: 0;

        @include mobile {
            align-items: center;
            font-size: 14px;
            justify-content: center;
            width: 90px;
            height: 38px;
        }

        input[type="number"] {
            background-color: #F9F9F9;
            min-width: 36px;
            width: 36px;
            height: 32px;
            font-size: 16px;

            @include mobile {
                width: 45px;
                min-width: 20px;
                background-color: #F9F9F9;
                font-size: 12px;
            }
        }

        button {
            &:not([disabled]) {
                cursor: pointer;
                width: 25px;
                height: 25px;
                margin: 5px;

                svg {
                    fill: #000000;
                    width: 25px;
                    height: 25px;
                }
            }

            &:not(:last-of-type) {
                @include mobile {
                    margin-inline-end: 0;
                }
            }

            &:is([disabled]) {
                cursor: pointer;
                border: none;
                margin: 5px;

                svg {
                    fill: #707070;
                }
            }
        }
    }

    &-QuantityWrapper {
        .Field_type_number [aria-label='Add'] svg, .Field_type_number [aria-label='Subtract'] svg {
            width: 24px;
            height: 24px;
        }

        // @include desktop {
        //     margin-block: 50px 1rem;
        // }
    }

    &-SwipeToDeleteRightSide {
        height: 100%;
        width: 100%;
        font-weight: 600;
        color: var(--color-white);
        background-color: var(--swipe-to-delete-bg-color);
    }

    .ProductPrice {
        @include desktop {
            min-height: auto;
            font-size: 16px;
            font-family: "Lato", sans-serif;
        }

        &.CartItem-Price {
            display: flex;
            flex-direction: column;
            margin-block-start: 10px;

            &_isCartOverlay {
                flex-direction: row;
                column-gap: 10px;
            }

            .RegularPrice {
                del {
                    margin-inline: 0;
                    font-size: 14px;
                }
            }

            &:has(.ProductPrice-DelPrice) {
                @include desktop {
                    margin-block-start: 35px;
                }

                data {
                    color: #E84620;
                }
            }

            &:has(.DiscountedPrice) {
                @include desktop {
                    margin-block-start: 25px;
                }
            }
        }

        &-SubPrice {
            font-size: 12px;
            font-weight: 400;
        }

        .ProductPrice Data {
            font-size: 16px;
        }
    }

    .CartItem-QuantityWrapper {
        &_isCartOverlay {
            margin-block-start: 2%;

            & button {
                width: 36px;
                height: 36px;
                border: none;

                svg {
                    fill: #707070;
                }
            }

            input {
                background-color: #f2faf8;
            }

            .CartItem-Qty {
                font-size: 14px;
                border-radius: 4px;

                input {
                    width: 32px;
                    min-width: 32px;
                    font-size: 13px;
                }
            }

            .Select-Qty {
                color: #707070;
                font-size: 14px;
                font-family: "Lato", sans-serif;
                margin-block-end: 2%;
            }
        }

        &_isPlaceholder {
            width: 144px;

            @include mobile {
                display: none;
            }
        }

        .Select-Qty {
            @include mobile {
                font-size: 12px;
                text-align: center;
                margin-block-end: 5px;
            }
        }

        &.Freebee {
            .CartItem-Qty {
                background: #ffffff;
            }

            button {
                opacity: 0;
                pointer-events: none;
            }

            input {
                background: #ffffff;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
            }
        }
    }

    .ProductPrice-DelPrice del {
        display: block;
        text-align: end;
        margin-inline-end: 0;
        color: #3A3A36;
        font-size: 14px;
    }
 
    &-MiddleLine {
        background: #dfdfdf;
        width: 100%;
        height: 1px;
    }

    &-StockStatus {
        margin-block-end: 0;
        padding: 6px;
        border-radius: 8px;
        font-weight: 600;
        white-space: nowrap;
        width: fit-content;

        &.instock {
            color: #73B486;
            padding: 0;
        }

        &.out-of-stock {
            background-color: #F3B7B7;
            color: #CD0909;
            display: flex;
            align-items: center;
            gap: 2px;
            padding: 2px;

            svg {
                fill: #CD0909;
            }
        }
    }

    &-ProductActionsSubContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:has(.out-of-stock) {
            .CartItem-FreebieContainer .CartItem-Qty {
                @include desktop {
                    margin-block: 3rem 2px;
                }
            }
        }
    }

    &-FreebieContainer {
        display: flex;
        align-items: center;
    }

    &-QuantityPriceWrapper.CheckoutSummary {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .CartItem {
            &-Price {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    &-container_isSummary {
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 8px;

        #CartItemAction.CartItem-ProductActions {
            padding: 10px;

            @include mobile {
                display: block;
                padding: 11px;
            }

            .ProductPrice {
                display: flex;
                margin-block-start: 0;
                font-size: 16px;
                font-weight: 600;
                color: #E84620;
            }
        }

        .CartItem-Quantity {
            margin-block-end: 0;
            font-weight: 600;
            font-size: 14px;
        }
    }
}
