/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable no-missing-end-of-source-newline */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable-next-line rule-empty-line-before */
.CartOverlay {
    cursor: auto;
    box-shadow: 0px 3px 9px #00000029;
    border: 1px solid #ededed;
    position: fixed;
    width: 100%;
    z-index: 9999;
    inset-inline-start: 0;
    inset-block-start: 0;
    height: 100%;
    overflow: auto;

    &::before {
        @include desktop {
            // content: '';
            position: absolute;
            inset-block-start: -8px;
            inset-inline-end: 9px;
            height: 15px;
            width: 15px;
            transform: rotate(45deg);
            background-color: #ffffff;
            box-shadow: 0px 3px 9px #00000029;
        }
    }

    &-CartOverlayContentBackground {
        width: calc(100vw - 2px);
        height: calc(100vh - 2px);
        position: absolute;
        z-index: 100;
        background: #00000060 0% 0% no-repeat padding-box;

        @include desktop {
            width: 100%;
        }
    }

    &-CartOverlayContentWrapper {
        background: #ffffff;
        width: 535px;
        height: calc(100vh - 2px);
        right: 0;
        position: absolute;
        z-index: 105;
        padding: 35px 0 0 0;

        @include mobile {
            width: 91%;
            padding: 36px 0;
            z-index: 105;
        }
    }

    &-ContentWrapper {
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 124px);

        @include desktop {
            height: calc(100vh - 125px);
        }
    }

    &-Empty {
        padding: 16px;
        text-align: center;
    }

    &-Actions {
        display: grid;
        grid-template-columns: 50% 50%;
        // padding: 4% 0%;

        @include desktop {
            padding: 20px 0 0;
            grid-template-columns: 47% 48%;
            grid-gap: 23px;
        }

        @include mobile {
            display: flex;
            padding: 16px 0 0;
        }
    }

    &-OutOfStockProductsWarning {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, --primary-base-color);
        color: var(--color-white);
        font-size: 14px;
        font-weight: 600;
    }

    &-CheckoutButton {
        // margin-inline-start: 12px;
        background-color: #ffffff;
        color: var(--primary-base-color);
        border: 1px solid #e53d2f;
        border-radius: 4px;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        white-space: nowrap;
        text-transform: none;
        height: 40px;

        &:not([disabled]):hover {
            height: 40px;

            @include mobile {
                height: 36px;
            }
        }

        @include mobile {
            margin-inline-start: 16px;
            width: 100%;
            display: block;
            text-align: center;
            height: 36px;
            font-size: 12px;
        }

        @include desktop {
            flex-grow: 1;
            text-align: center;
        }
    }

    &-CartButton {
        color: #bb8376;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #ba8377;
        border-radius: 4px;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        text-transform: none;
        height: 40px;

        &:not([disabled]):hover {
            height: 40px;
            background-color: #ad2413;
            color: #fff;

            @include mobile {
                height: 36px;
            }
        }

        @include mobile {
            height: 36px;
            font-size: 12px;
        }
    }

    &-Promo,
    &-Total {
        font-weight: 600;

        // @include mobile {
        //     min-height: 56px;
        // }

        @include desktop {
            min-height: 36px;
        }
    }

    &-Promo {
        padding: 12px;
        text-align: center;
        // background: var(--cart-overlay-promo-background)
        background-color: white;
        margin-block-end: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "Lato", sans-serif;
        font-weight: 100;

        @include mobile {
            border-block-end: 1px solid #9d9d9d;
            border-block-start: 1px solid #9d9d9d;
            padding: 20px 0;
            margin: 0 20px;
            min-height: unset;
        }

        @include desktop {
            padding: 16px 0px;
            color: #0f0f0f;
            background: #ffffff;
            border-bottom: 1px solid #b1abab;
            border-top: 1px solid #b1abab;
            border-width: thin;
            margin: 0 36px;
        }

        .Clear {
            cursor: pointer;
            color: var(--primary-base-color);
            margin-block-end: 0;
            text-decoration: underline;
        }

        strong {
            margin: 0 5px;
        }

        .items {
            @include desktop {
                margin-block-end: 0;
            }

            @include mobile {
                margin-block-end: 0;
            }
        }
    }

    &-Tax {
        font-weight: bold;
        font-size: 16px;
        justify-content: space-between;
        border-width: thin;
        padding: 0 0 5px;
    }

    &-Items {
        // padding-inline: 0% 2%;
        // max-height: 57vh;
        overflow-y: auto;
        line-height: 1.5;
        padding: 0 20px;

        @include desktop {
            padding-inline: 0;
            overflow-y: auto;
            margin: 0 36px;
        }

        &::-webkit-scrollbar {
            width: 5px; /* for vertical scrollbars */
            height: 1px; /* for horizontal scrollbars */
        }

        &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.5);
        }

        & .CartItem-ProductActions_isMobileLayout {
            flex-direction: row-reverse;

            .Remove {
                text-decoration: underline;
            }
        }

        & .CartItem-Qty button:not([disabled]) svg {
            width: 15px;
            height: 15px;
        }

        & .CartItem-Qty input[type="number"] {
            border: none;
            border-radius: 0;
        }
    }

    &-Total,
    &-Tax,
    &-Discount {
        word-wrap: break-word;
        display: flex;
        justify-content: space-between;
        padding: 0 0 5px;
        background: var(--cart-overlay-totals-background);

        // @include mobile {
        //     padding-block-start: 14px;
        //     padding-block-end: 7px;
        //     padding-inline: 14px;
        // }

        dd {
            text-align: end;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            white-space: nowrap;

            @include mobile {
                justify-content: flex-start;
            }

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-DiscountCoupon {
        word-break: break-word;
    }

    &-Discount {
        .label,
        .value {
            width: 45%;
        }
    }

    &-Total {
        font-weight: bold;
        font-size: 16px;
        justify-content: space-between;
        border-bottom: 1px solid #0f0f0f;
        // border-top: 1px solid #0F0F0F;
        border-width: thin;
        padding: 0 0 23px;

        @include mobile {
            padding: 0 0 16px;
        }
    }

    .Minicart {
        justify-content: space-between;
        align-items: center;
        display: flex;

        @include desktop {
            padding: 0 36px;
        }

        @include mobile {
            padding: 0 20px;
        }

        .Minicart-Title {
            font-size: 24px;
            font-weight: bold;
            font-family: $font-Montserrat;
            margin-block-end: 3%;

            @include mobile {
                font-size: 16px;
                margin-block-end: 12px;
            }
        }
    }

    &-TotalsWrapper {
        position: absolute;
        bottom: 0;
        background: white;
        width: 100%;
        z-index: 5;
    }

    &-Additional {
        box-shadow: 0px -3px 8px #0000001a;

        dl {
            .text, 
            .price {
                width: 40%;
            }
        }

        @include desktop {
            padding: 30px 36px;
        }

        @include mobile {
            padding: 16px 16px 16px 15px;
        }
    }
}

.Popup.Popup_isVisible.CartOverlay.CartOverlay_isVisible {
    justify-content: flex-start;
    align-items: baseline;

    .Popup-CloseBtn {
        @include desktop {
            right: 30px;
        }
    }

    .Popup-Content {
        border-radius: 0px;
        min-height: calc(100vh - 2px);
        -webkit-margin-start: auto;
        overflow: hidden;
        padding: 10px;
        width: 100%;

        @include desktop {
            max-width: 535px;
            padding: 56px 0 0;
        }
    }
}
